<template>
  <div>
    <v-sheet height="100vh" class="px-3">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <h1 class="primary--text text-center mt-15">LOGIN</h1>
        </v-col>
        <v-col class="text-center" cols="12">
          <v-card outlined max-width="600" flat class="mx-auto px-2 pt-6">
            <v-form>
              <v-text-field
                v-model="dform.username"
                prepend-inner-icon="mdi-account-circle"
                label="Username"
                color="accent"
                outlined
                dense
              >
              </v-text-field>

              <v-text-field
                v-model="dform.password"
                prepend-inner-icon="mdi-lock"
                label="Password"
                outlined
                color="accent"
                dense
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              >
              </v-text-field>
              <v-divider class="ma-2"></v-divider>
              <v-row justify="center" class="mt-3">
                <v-btn
                  color="accent"
                  :loading="loading"
                  class="mb-6"
                  @click="processLogin"
                >
                  Login
                </v-btn>
              </v-row>
              <v-row justify="center" class="mt-3" v-if="!isUserExists">
                <p>
                  Not a Member:
                  <router-link color="accent" :to="{ name: 'RegisterPage' }">
                    Register
                  </router-link>
                </p>
              </v-row>
            </v-form>
          </v-card>
          <h4 v-if="getMessages" class="success--text mt-7">
            {{ getMessages.successMessage }}
          </h4>
          <h4 v-if="getMessages" class="error--text mt-7">
            {{ getMessages.errorMessage }}
          </h4>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { apiClient } from "@/services";
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      showPassword: false,
      isUserExists: false,
      dform: {
        username: "",
        password: "",
      },
    };
  },

  methods: {
    ...mapActions(["login"]),
    processLogin() {
      this.loading = true;
      this.login(this.dform);
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["getMessages"]),
  },
  created() {
    apiClient
      .get("/admins/isUserExists")
      .then((res) => {
        this.isUserExists = res.data.isUserExists;
      })
      .catch((err) => {
        this.displayMsg(err.message, "error");
      });
  },
};
</script>

<style lang="scss" scoped></style>
